

import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import AppWrapper from './AppWrapper';
import Authentication from './models/authentication';
//import * as serviceWorker from './serviceWorker';

let authenModel = new Authentication()
authenModel.getUser().then(user => {
	ReactDOM.render(
		<HashRouter>
			<AppWrapper></AppWrapper>
		</HashRouter>,
		document.getElementById('root')
	);
})



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
